import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {OrderCartModel} from "../models/orders/OrderCartModel";
import {HttpService} from "./base/http.service";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";
import {WebsiteService} from "./website.service";
import {map} from "rxjs/operators";
import {OrderCartItemModel} from "../models/orders/OrderCartItemModel";
import {LocationService} from "./location.service";
import {MenuItemDialogComponent} from "../dialogs/menu-item-dialog/menu-item-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "./local-storage.service";
import {addMinutes, formatISO, getUnixTime, isBefore} from "date-fns";
import {OrderTypeChooserDialogComponent} from "../dialogs/order-type-chooser-dialog/order-type-chooser-dialog.component";
import {EnumWebsiteModules} from "../models/enums/EnumWebsiteModules";
import {ApiEndpoints} from "../utils/ApiEndpoints";
import {ProductService} from "./product.service";
import {OrderGenericResponseModel} from "../models/orders/OrderGenericResponseModel";
import {EnumResponseStatus} from "../models/enums/EnumResponseStatus";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomSnackBarComponent} from "../components/custom-snack-bar/custom-snack-bar.component";
import {SessionStorageService} from "./session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnDestroy {
  private bsCart = new BehaviorSubject<OrderCartModel>(new OrderCartModel());
  public cart$ = this.bsCart.asObservable();
  private bsOrderDate = new BehaviorSubject<Nullable<Date>>(null);
  private isOrderPickerOpen = false;

  private sub = new Subscription();

  constructor(private http: HttpService, private webSvc: WebsiteService, private locSvc: LocationService, private modal: MatDialog, private lsSvc: LocalStorageService,
              private prodSvc: ProductService, private snackBar: MatSnackBar, private sessionSvc: SessionStorageService) {
    this.bsCart.subscribe(data => {
      if (isStringNotEmptyGuid(data.id) && isStringNotEmptyGuid(data.locationToken)) {
        const timeStamp = data.isAsap ? null : getUnixTime(data.orderDate || new Date());
        this.prodSvc.executeByToken(data.locationToken, timeStamp);
      }
    });
  }

  public getCart(locationToken: string) {
    this.bsCart.next(new OrderCartModel());

    const w = this.webSvc.getCurrentWebsite();
    if (isStringNotEmptyOrWhitespace(w.id)) {
      let lsO = this.lsSvc.getCurrentLS();

      this.sub.add(
        this.http.get<OrderGenericResponseModel>(`${ApiEndpoints.cartInitialize}-${w.id}/${locationToken}/${lsO.items[locationToken] ?? ''}`)
          .pipe(
            map(f => new OrderGenericResponseModel(f)))
          .subscribe(response => {
            if (response == null) {
              return;
            }
            if (response.status === EnumResponseStatus.Error || response.status === EnumResponseStatus.Warning) {
              // this.snackBar.open(messageTemplate, 'Error');
              this.snackBar.openFromComponent(CustomSnackBarComponent, {data: {message: response.message, type: 'Warn', errors: response.errors}});
            }

            if (response.data != null) {

              if (this.sessionSvc.isAuth()){
                response.data.isGuestUser = false;
              }

              this.bsCart.next(response.data);
              this.validateInternal(response.data);

              if (isStringNotEmptyGuid(response.data.id)) {
                this.lsSvc.saveCartId(response.data.id, locationToken);
              }
            }
          })
      );
    }
  }

  public addToCart(item: OrderCartItemModel, isEdit = false) {
    const cart = this.bsCart.value;

    let ix = -1;
    if (cart.items != null && cart.items.length > 0 && isEdit)
      for (let i = 0; i < cart.items.length; i++) {
        const existItem = cart.items[i];
        if (existItem.token === item.token && existItem.cartItemId === item.cartItemId) {
          ix = i;
          break;
        }
      }

    if (ix > -1 && isEdit)
      cart.items[ix] = item;
    else
      cart.items.push(item);

    this.bsCart.next(cart);

    this.save(cart);
  }

  public save(cart: OrderCartModel) {
    const w = this.webSvc.getCurrentWebsite();
    const l = this.locSvc.getCurrentLocation();

    this.sub.add(
      this.http.post<OrderGenericResponseModel>(`${ApiEndpoints.cartSave}-${w.id}::${l.id}/`, cart).pipe(
        map(f => new OrderGenericResponseModel(f))
      ).subscribe(response => {
        if (response == null) {
          return;
        }
        if (response.status === EnumResponseStatus.Error || response.status === EnumResponseStatus.Warning) {
          // this.snackBar.open(messageTemplate, 'Error');
          this.snackBar.openFromComponent(CustomSnackBarComponent, {data: {message: response.message, type: 'Warn', errors: response.errors}});
        }

        if (response.data != null) {
          this.bsCart.next(response.data);
          this.validateInternal(response.data);

          if (isStringNotEmptyGuid(response.data.id)) {
            this.lsSvc.saveCartId(response.data.id, l.id);
          }
        }
      })
    )
  }


  removeFromCart(item: OrderCartItemModel) {
    const cart = this.bsCart.value;

    var ix = cart.items.indexOf(item);
    if (ix > -1) {
      cart.items.splice(ix, 1);
      this.bsCart.next(cart);

      this.save(cart);
    }
  }

  openDialog(cartItem: OrderCartItemModel, isEdit = false) {
    const dialogRef = this.modal.open(MenuItemDialogComponent, {
      minWidth: '400px',
      // maxWidth: '600px',
      maxWidth: '100vw',
      data: cartItem,
      panelClass: 'order-cart-dialog',
      autoFocus: 'first-heading',
      enterAnimationDuration: 300,
      exitAnimationDuration: 300
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result instanceof OrderCartItemModel) {
        this.addToCart(result, isEdit);
      }
    });
  }

  validate(cart: OrderCartModel) {
    const w = this.webSvc.getCurrentWebsite();
    const l = this.locSvc.getCurrentLocation();

    if (isStringNotEmptyGuid(w.id) && isStringNotEmptyGuid(l.id) && cart != null) {

      let date: Date = addMinutes(new Date(), l.leadTimeForOrders);
      if (!cart.isAsap && cart.orderDate != null)
        date = cart.orderDate;
      const orderDate = formatISO(date);
      const cartId = cart.id;

      this.http.get<OrderCartModel>(`${ApiEndpoints.cartValidate}::${w.id}::${l.id}::${cartId}::${orderDate}`).pipe(
        map(f => new OrderCartModel(f))
      ).subscribe(data => {
        this.bsCart.next(data);
      })
    }
  }

  public openOrderTypePicker(data: OrderCartModel, disableClose: boolean = false) {
    if (this.isOrderPickerOpen)
      return;

    this.isOrderPickerOpen = true;
    const dialogRef = this.modal.open(OrderTypeChooserDialogComponent, {
      maxWidth: '94vw',
      data: data,
      disableClose: disableClose,
      autoFocus: 'first-heading'
    });
    dialogRef.componentInstance.disableClose = disableClose;
    dialogRef.afterClosed().subscribe(result => {
      this.isOrderPickerOpen = false;
    });
  }

  public pushCart(cart: OrderCartModel) {
    this.bsCart.next(cart);
  }

  public getLatestCart(locationToken: string) {
    const cartValue = this.bsCart.value;
    return cartValue.locationToken === locationToken ? cartValue : null;
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  applyDiscount(cart: OrderCartModel, discountCode: string) {
    const webId = this.webSvc.getCurrentWebsite().id;
    const locId = this.locSvc.getCurrentLocation().id;

    // this should not be called when webId and locId are empty
    if (!isStringNotEmptyOrWhitespace(webId) || !isStringNotEmptyOrWhitespace(locId)) {
      return;
    }

    this.http.post<OrderGenericResponseModel>(`${ApiEndpoints.cartApplyDiscount}-${webId}::${locId}`, {cartId: cart.id, code: discountCode}).pipe(
      map(f => new OrderGenericResponseModel(f))
    ).subscribe(response => {
      if (response == null) {
        return;
      }
      if (response.status === EnumResponseStatus.Error || response.status === EnumResponseStatus.Warning) {
        // this.snackBar.open(messageTemplate, 'Error');
        this.snackBar.openFromComponent(CustomSnackBarComponent, {data: {message: response.message, type: 'Warn', errors: response.errors}});
      }

      if (response.data != null) {
        this.bsCart.next(response.data);
        this.validateInternal(response.data);

        if (isStringNotEmptyGuid(response.data.id)) {
          this.lsSvc.saveCartId(response.data.id, locId);
        }
      }
    })

  }

  removeDiscount(cart: OrderCartModel, discountCode: string) {
    const webId = this.webSvc.getCurrentWebsite().id;
    const locId = this.locSvc.getCurrentLocation().id;

    // this should not be called when webId and locId are empty
    if (!isStringNotEmptyOrWhitespace(webId) || !isStringNotEmptyOrWhitespace(locId)) {
      return;
    }

    this.http.post<OrderGenericResponseModel>(`${ApiEndpoints.cartRemoveDiscount}-${webId}::${locId}`, {cartId: cart.id, code: discountCode}).pipe(
      map(f => new OrderGenericResponseModel(f))
    ).subscribe(response => {
      if (response == null) {
        return;
      }
      if (response.status === EnumResponseStatus.Error || response.status === EnumResponseStatus.Warning) {
        // this.snackBar.open(messageTemplate, 'Error');
        this.snackBar.openFromComponent(CustomSnackBarComponent, {data: {message: response.message, type: 'Warn', errors: response.errors}});
      }

      if (response.data != null) {
        this.bsCart.next(response.data);
        this.validateInternal(response.data);

        if (isStringNotEmptyGuid(response.data.id)) {
          this.lsSvc.saveCartId(response.data.id, locId);
        }
      }
    })
  }

  private validateInternal(data: OrderCartModel) {
    if (!isStringNotEmptyGuid(data.id))
      this.openOrderTypePicker(data, true);
    else {
      const location = this.locSvc.getCurrentLocation();
      if (location.id == data.locationToken) {
        console.log('Cart - Same Location ID');
        const module = EnumWebsiteModules.OnlineOrder;
        const dtNow = new Date();
        if (data.isAsap) {
          if (!location.isOpen(module, dtNow)) {
            this.openOrderTypePicker(data, true);
          }
        } else {
          if (!location.isOpenScheduledOrders(dtNow)) {
            this.openOrderTypePicker(data, true);
          }
          else {
            location.processDays();
            const dayAvailable = location.daysAvailable.length > 0 ? location.daysAvailable[0] : null;
            const timeAvailable = dayAvailable != null && dayAvailable.times.length > 0 ? dayAvailable.times[0] : null;
            const leadDate = timeAvailable != null ? timeAvailable : addMinutes(dtNow, location.leadTimeForOrdersEnd);
            if (data.orderDate == null || isBefore(data.orderDate, leadDate)) {
              this.openOrderTypePicker(data, true);
            }
          }

        }
      }
    }
  }
}
