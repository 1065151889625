import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subject, Subscription, takeUntil} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {SessionStorageService} from "../../services/session-storage.service";
import {OtpAuthUserBaseDataModel} from "../../models/auth/OtpAuthUserBaseDataModel";
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {OrdersAuthResponseModel} from "../../models/auth/OrdersAuthResponseModel";
import {differenceInSeconds} from "date-fns";
import {map} from "rxjs/operators";
import {LocationService} from "../../services/location.service";
import {LocationModel} from "../../models/LocationModel";
import {EnumAuthResponseType} from "../../models/auth/EnumAuthResponseType";
import {isStringNotEmptyOrWhitespace} from "../../utils/Utils";
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatTooltip } from '@angular/material/tooltip';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgIf, MatIcon, MatSuffix, MatError, MatButton, NgOtpInputModule, MatTooltip, NgxMaskDirective, AsyncPipe, DecimalPipe, NgxMaskPipe]
})
export class AuthComponent implements OnInit, OnDestroy {
  @Output() public isAuth = new EventEmitter<boolean>();
  public currentStep$: Observable<number>;
  public currentUserData$: Observable<OtpAuthUserBaseDataModel>
  public currentAuthResponse$: Observable<OrdersAuthResponseModel>;
  public email: string = '';
  public otp: string = '';
  public infoGroup!: FormGroup;

  public location$: Observable<LocationModel>;
  protected readonly isStringNotEmptyOrWhitespace = isStringNotEmptyOrWhitespace;
  private sub = new Subscription();
  private txTimer: Observable<number> | undefined;
  private txTimer2: Observable<number> | undefined;
  private sTimerStop = new Subject<boolean>();
  private sTimerResendStop = new Subject<boolean>();
  private bsTimeLeft = new BehaviorSubject<[number, number]>([0, 0]);
  public timeLeft$ = this.bsTimeLeft.asObservable();
  private bsTimeLeftResend = new BehaviorSubject<number>(0);
  public timeLeftResend$ = this.bsTimeLeftResend.asObservable();

  constructor(private sessionSvc: SessionStorageService, private authSvc: AuthService, private locSvc: LocationService) {
    this.currentStep$ = this.authSvc.currentStep$;
    this.currentUserData$ = this.authSvc.currentUserData$;
    this.currentAuthResponse$ = this.authSvc.currentAuthResponse$;
    this.location$ = this.locSvc.location$;
  }

  ngOnInit(): void {
    if (this.sessionSvc.isAuth()) {
      this.isAuth.emit(true);
      this.authSvc.loadUserData();
    }
    this.infoGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(120)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(120)]),
      email: new FormControl('', [Validators.required, Validators.maxLength(240), Validators.email]),
      phone: new FormControl('', [Validators.maxLength(16), Validators.pattern(/\d{10}/)]),
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.bsTimeLeft.complete();
    this.sTimerStop.complete();
  }

  auth() {
    this.authSvc.authEmail(this.infoGroup.controls['email'].value).subscribe(data => {
      if (data.expiresOn != null) {
        const diffInSecs = differenceInSeconds(data.expiresOn, new Date(), {roundingMethod: 'ceil'});
        this.initTimer(diffInSecs);
      }
    });
  }

  otpChange(evt: string) {
    this.otp = evt;
    if (evt.length == 6) {
      this.verify();
    }
  }

  verify() {
    this.authSvc.verifyOtp(this.otp).subscribe(data => {
      if (data != null && data.responseType === EnumAuthResponseType.OtpValid) {
        this.sTimerStop.next(true);
        this.isAuth.emit(true);
      }
    });
  }

  register() {
    this.authSvc.register(new OtpAuthUserBaseDataModel(this.infoGroup.value)).subscribe(data => {
      if (data.expiresOn != null) {
        const diffInSecs = differenceInSeconds(data.expiresOn, new Date(), {roundingMethod: 'ceil'});
        this.initTimer(diffInSecs);
      }
    });
  }

  reset() {
    this.authSvc.resetUser();
    this.sTimerStop.next(true);
    this.isAuth.emit(false);
  }

  resendOtp() {
    this.authSvc.resendOtp().subscribe(data => {
      if (data.expiresOn != null) {
        const diffInSecs = differenceInSeconds(data.expiresOn, new Date(), {roundingMethod: 'ceil'});
        this.initTimer(diffInSecs);
      }
    });
  }

  private initTimer(diffInSecs: number) {
    if (diffInSecs <= 0)
      return;
    this.sTimerStop.next(false);
    this.sTimerResendStop.next(false);
    this.txTimer = interval(1000).pipe(takeUntil(this.sTimerStop), map(f => diffInSecs - f));
    this.txTimer2 = interval(1000).pipe(takeUntil(this.sTimerResendStop), map(f => 30 - f));

    this.sub.add(this.txTimer.subscribe(data => {
      // console.log(data);
      if (data <= 0) {
        this.sTimerStop.next(true);
        this.bsTimeLeft.next([0, 0]);
      } else
        this.bsTimeLeft.next([Math.floor(data / 60), data % 60]);
    }));
    this.sub.add(this.txTimer2.subscribe(data => {
      if (data <= 0) {
        this.sTimerResendStop.next(true);
        this.bsTimeLeftResend.next(0);
      } else
        this.bsTimeLeftResend.next(data);
    }));
  }
}
